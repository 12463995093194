var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "own-space" },
    [
      _c("Card", [
        _c("div", { staticClass: "own-wrap" }, [
          _c(
            "div",
            { staticStyle: { width: "240px" } },
            [
              _c(
                "Menus",
                {
                  attrs: { "active-name": _vm.activeName, theme: "light" },
                  on: { "on-select": _vm.changeMenu },
                },
                [
                  _c("MenuItem", { attrs: { name: "基本信息" } }, [
                    _vm._v("基本信息"),
                  ]),
                  _c("MenuItem", { attrs: { name: "安全设置" } }, [
                    _vm._v("安全设置"),
                  ]),
                  _c("MenuItem", { attrs: { name: "第三方账号绑定" } }, [
                    _vm._v("第三方账号绑定"),
                  ]),
                  _c("MenuItem", { attrs: { name: "消息通知" } }, [
                    _vm._v("消息通知"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { padding: "8px 40px", width: "100%" } }, [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.currMenu))]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currMenu == "基本信息",
                    expression: "currMenu == '基本信息'",
                  },
                ],
              },
              [_c("user", { on: { "on-success": _vm.updateUserInfo } })],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currMenu == "安全设置",
                    expression: "currMenu == '安全设置'",
                  },
                ],
              },
              [_c("security", { on: { "on-success": _vm.updateUserInfo } })],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currMenu == "消息通知",
                    expression: "currMenu == '消息通知'",
                  },
                ],
              },
              [_c("message")],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }